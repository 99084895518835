import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { switchMap } from 'rxjs/operators';

import { PhoneNumbersStore, PhoneNumbersState } from './phoneNumbers.store';


@Injectable({ providedIn: 'root' })
export class PhoneNumbersQuery extends QueryEntity<PhoneNumbersState> {

  selectPhoneNumber$ = this.routerQuery
                       .selectParams('id')
                       .pipe(switchMap(id => this.selectEntity(id as number)));


  constructor(protected store: PhoneNumbersStore, private routerQuery: RouterQuery) {
    super(store);
  }

}
