import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { PhoneNumber } from './phoneNumbers.model';


export interface PhoneNumbersState extends EntityState<PhoneNumber, number>, ActiveState { }


@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'phoneNumbers' })
export class PhoneNumbersStore extends EntityStore<PhoneNumbersState> {

  constructor() {
    super();
  }

}

