import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';

import { PhoneNumbersStore, PhoneNumbersState } from './phoneNumbers.store';


@Injectable({ providedIn: 'root' })
export class PhoneNumbersService extends NgEntityService<PhoneNumbersState> {

  constructor(protected store: PhoneNumbersStore) {
    super(store);
  }

}
